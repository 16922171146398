import Box from "@mui/material/Box";
import {CardMedia} from "@mui/material";
import {EditableText} from "../Editable";
import {useDeleteBookImgMutation, useUpdateTitleImgMutation} from "../../redux/book/bookApi";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

const ImgCard = ({cover, bookId})=> {
    const [updateTitleImg] = useUpdateTitleImgMutation()
    const [deleteBookImg] = useDeleteBookImgMutation()

    const onClickDelete = () => {
        deleteBookImg({id: bookId,imgId: cover.id})
    }

    return (
   <Box sx={{maxWidth:"150px"}}>
       <Box sx={{position:'relative'}}>
       <CardMedia
           key={cover.id}
           component="img"
           sx={{ width: "124px", marginRight:2, height: "188px",borderRadius: "15%", border:"2px solid black", boxShadow: "11px 10px 5px -9px rgba(0,0,0,0.75);" }} // Adjust the margin as needed
           image={cover.href}
           alt={cover.title}
       />
           <IconButton onClick={onClickDelete} sx={{position:'absolute', top:-15, right:-5}}><CloseIcon/></IconButton>
       </Box>
       <Box marginTop={2}>
            <EditableText  initialText={cover.title} data={{id:bookId,imgId:cover.id}} editKey={'title'} onUpdate={updateTitleImg}/>
       </Box>
       </Box>
    );
}

export default ImgCard
