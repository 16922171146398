import React, {useState} from 'react';
import {Button, Card, CardContent, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {addPartNow, modalOpen} from "../../redux/app/appSlice";
import {useDispatch} from "react-redux";
import {changeParentId, changeParentIdPage} from "../../redux/creators/creatorsSlice";


const PartBook = ({ part, recursion }) => {
    const [open, setOpen] = useState(true);
    const [searchNumber, setSearchNumber] = useState("");
    const dispatch = useDispatch();
    const handleSearchChange = (event) => {
        setSearchNumber(event.target.value);
    };

    const filterPagesByNumber = (page) => {
        if (searchNumber === "") {
            return true;
        }
        return page.num.toString().includes(searchNumber);
    };

    const renderPages = (pages) => {
        return pages
            .filter(filterPagesByNumber)
            .map((page) => (
                <Button
                    size={'small'}
                    sx={{ maxWidth: '80px' }}
                    variant={"outlined"}
                    component={Link}
                    to={`${part.id}/${page.id}`}
                    key={page.uid}
                >
                    {page.num}
                </Button>
            ));
    };

    const onClickAddPart = (parentId) => {
        dispatch(modalOpen('createPart'));
        dispatch(changeParentId(parentId));
    };
    const onClickAddPage = (parentId) => {
        dispatch(modalOpen('createPage'));
        dispatch(changeParentIdPage(parentId));
    };

    const onClickDeletePart = (partId)=>{
        dispatch(modalOpen('DeletePart'))
        dispatch(addPartNow(partId))
    }

    const renderChildren = (children) => {
        return children.map((child) => (
            <React.Fragment key={child.id}>
                <PartBook part={child}
                          recursion />
            </React.Fragment>
        ));
    };

    return (
        <Card sx={{ marginBottom: '15px' }}>
            <Box display={'flex'} alignItems={'center'} marginTop={1}>
                <Box>
                    <IconButton onClick={() => { setOpen(prev => !prev) }}>
                        {open ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                </Box>
                <Typography>{part.name}</Typography>
                <Box marginLeft={'auto'} marginRight={3} marginBottom={1}>
                    {part.pages ? <TextField
                        type="search"
                        value={searchNumber}
                        onChange={handleSearchChange}
                        placeholder="Введите номер"
                        variant={"outlined"}
                        size={'small'}
                    /> : null
                    }
                </Box>
            </Box>
            {open ? (
                <>
                    {part.children ?
                        <CardContent sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px', flexDirection:'column' }}>
                            <CardContent>
                                {renderChildren(part.children)}
                            </CardContent>
                            <Button onClick={() => onClickAddPart(part.id)} size={'small'} variant={'outlined'}> Добавить раздел </Button>
                            <Button variant={'outlined'} color={'error'} onClick={()=>onClickDeletePart(part.id)}> Удалить раздел</Button>
                        </CardContent> : null}
                    {part.pages ? <CardContent sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {renderPages(part.pages)}
                        <Button onClick={()=>onClickAddPage(part.id)} size={'small'} variant={'outlined'}> Добавить страницу </Button>
                        <Button sx={{marginLeft:'auto'}} variant={'outlined'} size={'small'}  color={'error'} onClick={()=>onClickDeletePart(part.id)}> Удалить раздел</Button>

                    </CardContent> : null}
                        {!part.pages && !part.children ? <CardContent sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center', justifyContent: 'space-around' }}>
                       <Box display={'flex'}>
                               <Button size={'small'} variant={'outlined'} onClick={() => onClickAddPart(part.id)}> Добавить раздел </Button>
                               <Typography marginRight={1} marginLeft={1}> или </Typography>
                               <Button onClick={()=>onClickAddPage(part.id)} size={'small'} variant={'outlined'}> Добавить страницу </Button>
                       </Box>
                            <Button variant={'outlined'} size={'small'}  color={'error'} onClick={()=>onClickDeletePart(part.id)}> Удалить раздел</Button>
                        </CardContent> : null}
                </>
            ) : null}


        </Card>
    );
};

export default React.memo(PartBook);
