import {useParams} from "react-router-dom";
import {useGetBooksBySubjectQuery, useGetSubjectQuery} from "../../redux/public/publicApi";
import {Grid, Typography} from "@mui/material";
import {BookCard} from "../../components/Cards";
import useUpdateTitle from "../../hook/useUpdateTitle";
import Box from "@mui/material/Box";
import EditableHtml from "../../components/Editable/EditableHtml";
import {useUpdateSubjectsMutation} from "../../redux/selects/selectsApi";

const SubjectBooks = () => {
    const { subjectId } = useParams();
    const { data } = useGetBooksBySubjectQuery({ subjectId });
    const { data:subjects } = useGetSubjectQuery();
    const subjectName = subjects?.find(subjects=>subjects.uid === subjectId)?.name
    useUpdateTitle(subjectName ? `книги ${subjectName}` : null)
   const [ updateSubjects ] = useUpdateSubjectsMutation()
        const subject = subjects?.find(subjects=>subjects.uid ===subjectId)
    return (
        <>
            <Box>
                {data?.books.map((book) => (
                    <>
                    <Box>
                        <Typography textAlign={'center'} marginBottom={2} variant={'h4'}> {book.name} </Typography>
                    </Box>
                        <Grid container spacing={5}>
                            {book.books.map((book)=>{
                             return <Grid  item key={book.id}>
                                <BookCard book={book} />
                             </Grid>
                            })}
                        </Grid>
                    </>
                ))}
            </Box>
{/*            <Box>
                {data?.descriptionBottom && <EditableHtml html={data?.descriptionBottom} data={subject} title={"Нижнее описание:"} editKey={'descriptionBottom'} onUpdate={updateSubjects}/>}
            </Box>*/}
        </>
    );
};

export default SubjectBooks;
