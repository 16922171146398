import React from 'react';
import styles from './styles.module.css';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import { Link, useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: 'center',
        '&:hover:not(:first-of-type)': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textAlign: 'center',
        ':first-of-type': {
            textAlign: 'right',
        },
        '&:hover:not(:first-of-type)': {
            backgroundColor: theme.palette.action.active,
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
        '&:hover:first-of-type': {
            backgroundColor: theme.palette.action.active,
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

const SubjectTables = ({ data }) => {
    const navigate = useNavigate();
    if (!data || data.length === 0) {
        return null;
    }

    const headerRow = data[0];
    const tableData = data.slice(1);

    const onClickCellLink = (cellData) => {
        if (!cellData?.hasSubject) {
            return;
        }
        navigate(`/${cellData.uidClass}/${cellData.uidSubject}`);
    };
    const onClickFirstCellLink = (cellData,rowIndex) => {
        console.log(cellData)
        navigate(`/subject/${cellData.uidSubject}`);
    };
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {headerRow.map((headerCell, index) => (
                            <StyledTableCell key={headerCell.id}>
                                {index === 0 ? (
                                    headerCell.name
                                ) : (
                                    <Link className={styles.linkTable} to={`${headerCell.uid}`}>
                                        {headerCell.name}
                                    </Link>
                                )}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((rowData, index) => (
                        <StyledTableRow key={`${index}`}>
                            {rowData.map((cellData, cellIndex) => {
                                if (cellIndex === 0) {
                                    return (
                                        <StyledTableCell onClick={()=>onClickFirstCellLink(cellData,index)} key={`${cellData.uidClass}/${cellData.uidSubject}`} align="right">
                                            {cellData.name}
                                        </StyledTableCell>
                                    );
                                } else {
                                    return (
                                        <StyledTableCell
                                            onClick={() => onClickCellLink(cellData)}
                                            key={`${cellData.uidClass}/${cellData.uidSubject}`}
                                            align="right"
                                            style={
                                                cellData.hasSubject === false
                                                    ? { backgroundColor: 'transparent', cursor: 'auto' }
                                                    : {}
                                            }
                                        >
                                            {cellData.hasSubject ? <CheckIcon color={'success'} /> : null}
                                        </StyledTableCell>
                                    );
                                }
                            })}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubjectTables;
