import {useNavigate, useParams} from "react-router-dom";
import { useGetClassesQuery} from "../../redux/public/publicApi";
import Box from "@mui/material/Box";
import {Button, CardMedia} from "@mui/material";

import useUpdateTitle from "../../hook/useUpdateTitle";
import PartBook from "../../components/partBook";
import EditableHtml from "../../components/Editable/EditableHtml";
import Loader from "../../components/Loader";
import {useEffect, useState} from "react";
import {EditableTable} from "../../components/Tables";
import {EditableText} from "../../components/Editable";
import {getTranslit} from "../../helpers/getTranslit";
import {useDispatch} from "react-redux";
import {
    useGetPublishersQuery,
    useGetSeriesQuery,
    useGetTypesQuery
} from "../../redux/selects/selectsApi";
import {changeParentId} from "../../redux/creators/creatorsSlice";
import {addBookNow, modalOpen, setNotification} from "../../redux/app/appSlice";
import {
    useDeleteBookMutation,
    useGetBookPartsQuery,
    useGetBookQuery,
    useUpdateBookMutation
} from "../../redux/book/bookApi";
import SearchSelect from "../../components/SearchSelect";
import ImgCard from "../../components/ImgCard";

const Book = () => {
    const { classId ,subjectId, bookId } = useParams();
    const { data: book,isLoading, refetch } = useGetBookQuery(bookId);
    const { data: parts} = useGetBookPartsQuery(bookId);
    const { data:types } = useGetTypesQuery();
    const { data:series } = useGetSeriesQuery();
    const { data:publisher } = useGetPublishersQuery();
    const { data:classes } = useGetClassesQuery();
    const [updateBook] = useUpdateBookMutation()
    const [deleteBook] = useDeleteBookMutation()

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [dataTable, setDataTable] = useState([])

    useUpdateTitle(book?.name?book.name:null)

    const onChangeText = (item)=>{
            updateBook({...item, parts: undefined })
    }

    useEffect(()=>{
        const draftDataTable= [
            {name:'Мета',id:'description',data:book?.description?book.description:"",onChange:onChangeText},
            {name:'Ключи',id:'keywords',data:book?.keywords?book.keywords:"",onChange:onChangeText},
        ]
        setDataTable(draftDataTable)
    },[book])

    if(isLoading){
        return <Loader/>
    }
    const onChangeName =(item)=>{
        const newUid = getTranslit(item.name)
        updateBook({...item,uid:newUid, parts: undefined})

    };

    const onChangeSelected = (item,editKey)=>{
        if(editKey === 'type') {
            updateBook({...book,[editKey]:item, parts: undefined })
        } else if (editKey === 'class'){
            updateBook({...book,[editKey]:item, parts: undefined})
            navigate(`/${item.uid}/${subjectId}/${bookId}`)
        } else {
            updateBook({...book,[editKey]:[item], parts: undefined})
        }
        dispatch(setNotification("Изменено"))
        refetch()
    }
    const onClickAddPart = (parentId)=>{
        dispatch(modalOpen('createPart'))
        dispatch(changeParentId(parentId))
    }

    const openUploadImg =()=>{
        dispatch(addBookNow(book.id))
        dispatch(modalOpen('UploadImage'))
    }

   const onClickDeleteBook = ()=>{
       dispatch(modalOpen('DeleteBook'))
   }

    return (
        <Box>
      <Box display="flex" flexDirection="row">
            { book && book.covers && book.covers.map((cover) => (
               <ImgCard key={cover.id} cover={cover} bookId={book.id}/>
            ))}
           <Button
                variant="contained"
                component="label"
                sx={{ textAlign:"center", width: "124px", marginRight:2, height: "188px",borderRadius: "15%", border:"2px solid black", boxShadow: "11px 10px 5px -9px rgba(0,0,0,0.75);" }}
                onClick={openUploadImg}
           >
                Добавить обложку
            </Button>
            <Box>
                <Box display='flex' gap={2} justifyContent={'space-between'}>
{/*                   <Box sx={{width:"250px"}}>
                        <SearchSelect editKey={'subject'} option={subjects} placeholder='Предмет' onChange={onChangeSelected} value={book.subject}/>
                    </Box>*/}
                    <Box sx={{width:"250px"}}>
                        <SearchSelect editKey={'class'} option={classes} placeholder='Класс' onChange={onChangeSelected} value={book.class}/>
                    </Box>
                    <Button size={'small'} variant={'outlined'} color={'error'} onClick={onClickDeleteBook}> Удалить Книгу </Button>
                </Box>
                <Box sx={{maxWidth:'30vw'}}>
                <Box>
                    <EditableText editKey={'name'} data={book} onUpdate={onChangeName} initialText={book.name} variant={'h7'} title={'Название книги:'} />
                </Box>
                <Box>
                    <EditableText editKey={'uid'} data={book} onUpdate={onChangeText} initialText={book.uid} variant={'h7'} title={'UID:'} />
                </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' gap={2}>
                <Box sx={{width:"250px"}}>
                    <SearchSelect editKey={'type'} option={types} placeholder='Типы' onChange={onChangeSelected} value={book.type}/>
                </Box>
                <Box sx={{width:"250px"}}>
                    <SearchSelect editKey={'series'} option={series} placeholder='Серия' onChange={onChangeSelected} value={book.series}/>
                </Box>
                <Box sx={{width:"250px"}}>
                    <SearchSelect editKey={'publishers'} option={publisher} placeholder='Издатель' onChange={onChangeSelected} value={book.publishers}/>
                </Box>
                </Box>
            </Box>
        </Box>
            <Box sx={{marginTop:3}}>
            <EditableTable table={dataTable} data={book}/>
            </Box>
            <Box sx={{marginTop:3}}>
                <EditableHtml html={book.descriptionTop} title={"Верхнее описание:"} editKey={'descriptionTop'} onUpdate={onChangeText} data={book}/>
            </Box>
            <Box>
                {parts?.map(part=><PartBook key={part.id} part={part}/>)}
                <Button onClick={()=>onClickAddPart(null)} variant={'outlined'}>Добавить раздел</Button>
            </Box>
            <Box sx={{marginTop:3}}>
                <EditableHtml html={book.descriptionBottom} title={"Нижнее описание:"} editKey={'descriptionBottom'} onUpdate={onChangeText} data={book}/>
            </Box>
        </Box>
    );
};

export default Book;
