import {lazy} from "react";
import Loadable from "../../components/Loadable";
import {MainLayout} from "../../layout/mainLayout/MainLayout";
import SubjectBooks from "../../pages/subjectBooks/SubjectBooks";
import {Parts} from "../../pages/parts";

const Main = Loadable(lazy(() => import('../../pages/main/Main')));
const Class = Loadable(lazy(() => import('../../pages/class/Class')));
const ListBook = Loadable(lazy(() => import('../../pages/listBook/listBook')));
const Book = Loadable(lazy(() => import('../../pages/book/Book')));
const Page = Loadable(lazy(() => import('../../pages/page/Page')));
const Edit = Loadable(lazy(() => import('../../pages/edit/Edit')));
const EditSelect = Loadable(lazy(() => import('../../pages/editSelect/EditSelect')));
const PageNotFound = Loadable(lazy(() => import('../../pages/pageNotFound/PageNotFound')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Main/>,
      breadcrumb: 'Главная',
    },
    { path:'/:classId',
      element:<Class/>,
      breadcrumb: 'Классы'
    },
    { path:'/:classId/:subjectId',
      element: <ListBook/>,
      breadcrumb: 'Список книг'
    },
    { path:'/:classId/:subjectId/:bookId',
      element: <Book/>,
      breadcrumb: 'Список книг'
    },
    { path:'/subject/:subjectId',
      element: <SubjectBooks/>,
      breadcrumb: 'Список Книг в Предмете'
    },
    { path:'/:classId/:subjectId/:bookId/:partId',
      element: <Parts/>,
      breadcrumb: 'Список Частей'
    },

    { path:'/:classId/:subjectId/:bookId/:partId/:pageId',
      element: <Page/>,
      breadcrumb: 'Список книг'
    },
    { path:'/edit',
      element: <Edit/>,
      breadcrumb: 'Список книг'
    },
    { path:'/edit/:selectId',
      element: <EditSelect/>,
      breadcrumb: 'Список книг'
    },
    {
      path: '/404',
      element: <PageNotFound/>
    },
    {
      path: '*',
      element: <PageNotFound/>
    },
  ]
};

export default MainRoutes;
