import React from 'react';
import {TableRow, TableContainer, Table, TableBody, TableCell} from "@mui/material";
import Paper from "@mui/material/Paper";
import EditableCell from "./EditableCell";


const EditableTable = ({ table, data }) => {

    return (
        <TableContainer component={Paper}>
            <Table  aria-label="customized table">
                <TableBody>
                    { table?.map((row)=>{
                       return <TableRow key={row.id}>
                           <TableCell sx={{fontWeight:'bold', width:'40px'}}>{row.name}:</TableCell>
                           <EditableCell row={row} data={data}/>
                       </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EditableTable;
