import {useRoutes} from 'react-router-dom';

// project import
//import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import LoginRoutes from "./LoginRoutes";
import {selectAuth} from "../../redux/app/appSlice";
import {useSelector} from "react-redux";

// ==============================|| ROUTING RENDER ||============================== //

export function Routes() {
  const {token,xToken} = useSelector(selectAuth)
  return useRoutes(token && xToken ?[MainRoutes]:[LoginRoutes]);
}
