import './App.css';
import {Routes} from "./data/routes";
import ScrollToTop from "./components/ScrollToTop";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import findGetParameter from "./helpers/findGetParameter";
import {selectNotifications, setSecretKey} from "./redux/app/appSlice";
import Notifications from "./components/Notification/Notifications";
import ErrorBoundary from "./components/ErrorBoundary";


function App() {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    useEffect(() => {
        const xToken = findGetParameter('xToken');
        if (xToken) dispatch(setSecretKey(xToken));
        // eslint-disable-next-line
    }, []);
    return (
      <>
          <ErrorBoundary>
            <Notifications notifications={notifications}/>
            <ScrollToTop/>
            <Routes/>
          </ErrorBoundary>
        </>
  );
}

export default App;
