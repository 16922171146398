import React, { useEffect, useState } from 'react';
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { setNotification } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&:hover': {
        color: theme.palette.grey.A400,
    },
}));

const EditableCell = ({ row, data }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(row.data);
    const dispatch = useDispatch();

    useEffect(() => {
        setText(row.data);
    }, [row.data]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        if (row.onChange) {
            row.onChange({ ...data, [row.id]: text });
            dispatch(setNotification("Изменено"));
        }
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSaveClick();
        } else if (e.keyCode === 27) {
            // Если нажата клавиша "Esc", выход из режима редактирования без сохранения
            setIsEditing(false);
            setText(row.data); // Восстановление исходного текста
        }
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    return (
        <StyledTableCell
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
        >
            {isEditing ? (
                <TextField
                    size={'small'}
                    value={text}
                    onChange={handleTextChange}
                    onBlur={handleSaveClick}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    fullWidth
                    variant={'standard'}
                    sx={{ fontSize: '10px' }}
                    type={row.type ? row.type : 'text'}
                />
            ) : (
                <Typography>{text ? text : '---'}</Typography>
            )}
        </StyledTableCell>
    );
};

export default EditableCell;
