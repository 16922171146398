import React from 'react';
import {Card, CardContent, CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";


const BookCard = ({ book }) => {
    if (!book.name) {
        return null;
    }

    return (
        <Card sx={{ display: 'flex', width:"425px",height:"200px", marginBottom:"10px", marginRight:"10px" }} component={Link} to={""+book.id}>
            <Box margin={1}>
                {book.cover?
            <CardMedia
                component="img"
                sx={{ boxShadow: "11px 10px 5px -9px rgba(0,0,0,0.75);" }}
                image={book.cover.href}
                alt={book.cover.title}
            />:null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6">
                        {book.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {book.author}
                    </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                        {book.publishers.map(item => item.name).join(', ')} {book.publicationYear}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        { book.series ? book.series.map(item => item.name).join(', '):null }
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {book.type.name}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
};

export default BookCard;
