import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "../baseQuery";

export const selectsApi = createApi({
  reducerPath: 'api/selects',
  baseQuery,
  endpoints: builder => ({
    getTypes: builder.query({
      query: () => ({
        url: 'types',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    addTypes: builder.mutation({
      query: data => ({
        url: 'types',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['public'],
    }),
    updateTypes: builder.mutation({
      query: data => ({
        url: `types/${data.id}`,
        method: 'PUT',
        body: {name:data.name}
      }),
    }),
    deleteTypes: builder.mutation({
      query: data => ({
        url: `types/${data}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['public'],
    }),
    getSeries: builder.query({
      query: () => ({
        url: 'series',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    addSeries: builder.mutation({
      query: data => ({
        url: 'series',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['public'],
    }),
    updateSeries: builder.mutation({
      query: data => {
        return {
          url: `series/${data.id}`,
          method: 'PUT',
          body: {name:data.name}
        }},
    }),
    deleteSeries: builder.mutation({
      query: data => ({
        url: `series/${data}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['public'],
    }),
    getSubjects: builder.query({
      query: () => ({
        url: 'subjects',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    addSubjects: builder.mutation({
      query: data => ({
        url: 'subjects',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['public'],
    }),
    updateSubjects: builder.mutation({
      query: data => ({
        url: `subjects/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['public'],
    }),
    deleteSubjects: builder.mutation({
      query: data => ({
        url: `subjects/${data}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['public'],
    }),
    getPublishers: builder.query({
      query: () => ({
        url: 'publishers',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    addPublishers: builder.mutation({
      query: data => ({
        url: 'publishers',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['public'],
    }),
    updatePublishers: builder.mutation({
      query: data => ({
        url: `publishers/${data.id}`,
        method: 'PUT',
        body: {name:data.name}
      }),
    }),
    getClass: builder.query({
      query: (data) => ({
        url: `classes/${data}`,
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    updateClass: builder.mutation({
      query: data => ({
        url: `classes/${data.id}`,
        method: 'PUT',
        body: {...data,id:undefined}
      }),
      invalidatesTags: ['public'],
    }),
    deletePublishers: builder.mutation({
      query: data => ({
        url: `publishers/${data}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['public'],
    }),
  })
});

export const { useGetTypesQuery, useUpdateTypesMutation ,useAddTypesMutation, useDeleteTypesMutation ,useGetSeriesQuery, useUpdateSeriesMutation ,useAddSeriesMutation, useDeleteSeriesMutation ,useGetSubjectsQuery, useFindSubjectsUidQuery , useAddSubjectsMutation, useDeleteSubjectsMutation ,useUpdateSubjectsMutation, useGetPublishersQuery , useUpdatePublishersMutation, useAddPublishersMutation, useDeletePublishersMutation, useGetClassQuery, useUpdateClassMutation } = selectsApi;
