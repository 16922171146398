import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {logoutUser, setNotification} from "./app/appSlice";

const API_URL = process.env.REACT_APP_API_URL;

const query = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const { xToken, token } = getState().app.auth;
     if (xToken) {
         headers.set('X-API-Key', `${xToken}`);
     }      if (token) {
          headers.set('authorization', `Bearer ${token}`);
      }
     return headers;
   },
  });

const baseQuery = async (args, api, extraOptions) => {
  const result = await query(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401) {
      api.dispatch(logoutUser());
    }
    result.error.data.error.forEach(text => {
      api.dispatch(setNotification(text, 'error'))
    });
  }
  return result;
}

export default baseQuery;
