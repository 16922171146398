import React from 'react';
import {Alert} from "@mui/material";


const Notifications = ({ notifications = [] }) => {
    return (
        <div style={{position:'absolute', width:"100%", top:0,zIndex:1203}}>
            {notifications.map((notification)=><Alert key={notification.id} severity={notification.type}>{notification.content}</Alert>)}
        </div>
    );
};

export default Notifications;
