import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
    useAddBookImgMutation,
    useGetBookQuery, useGetPageQuery,
    useUpdateBookMutation, useUpdatePageMutation,
    useUploadBookImgMutation
} from "../../redux/book/bookApi";
import {useDispatch, useSelector} from "react-redux";
import {bookNow, modalClose} from "../../redux/app/appSlice";
import {useParams} from "react-router-dom";

const UploadImg = ({placeholder, textButton,type}) => {
    const bookId = useSelector(bookNow);
    const dispatch =useDispatch()
    const { data:book} = useGetBookQuery(bookId,{skip:type === 'page'});
    const { partId ,pageId } = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const [previewImage, setPreviewImage] = useState(null);
    const { data: pageData } = useGetPageQuery({ bookId, pageId, partId },{skip:type === 'book'});
    const [updatePage] = useUpdatePageMutation()
    const [addBookImg] = useAddBookImgMutation()
    const [uploadBookImg] = useUploadBookImgMutation()
    const [updateBook] = useUpdateBookMutation()

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const objectURL = URL.createObjectURL(file);
            setPreviewImage(objectURL);
        }
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleUpload = async () => {
        const result = await addBookImg({id:bookId, title })
        console.log(result,'result: ')
        await uploadBookImg({file:selectedFile, id:bookId, imgId:result.data.id})
        if (type === 'book'){
        const covers = book?.covers?.length >= 0 ? [...book.covers, {id: result.data.id,
            ordr: book.covers.length + 1}] : [{id: result.data.id,ordr:1}]
            await updateBook({...book,covers})
            dispatch(modalClose())
        } else if (type === 'page') {
            console.log(result.data,'page')
            updatePage({bookId, pageId, partId,page:{...pageData,image:{id:result.data?.id}}})
            dispatch(modalClose())
        }

    };

    return (
        <Box sx={{display:"flex", flexDirection:'column', gap:3 }}>
                <TextField
                    type="text"
                    placeholder={placeholder}
                    value={title}
                    onChange={handleTitleChange}
                />
                <Button
                    variant="contained"
                    component="label"
                    disabled={!title}
                    sx={{
                        textAlign: "center",
                        width: "124px",
                        marginRight: 2,
                        height: "188px",
                        borderRadius: "15%",
                        border: "2px solid black",
                        boxShadow: "11px 10px 5px -9px rgba(0,0,0,0.75);",
                        backgroundImage: `url(${previewImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    {previewImage ? null : textButton}
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        accept="image/*"
                    />
                </Button>

            <Button disabled={!title || !selectedFile} variant="contained" onClick={handleUpload}>Загрузить</Button>
        </Box>
    );
};

export default UploadImg;
