import {Dialog, DialogTitle} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {isModalOpen, modalClose} from "../../redux/app/appSlice";
import Box from "@mui/material/Box";
import CreatePart from "../CreatePart";
import CreateBook from "../CreateBook";
import CreatePage from "../CreatePage";
import UploadImg from "../UploadImg";
import DeleteModal from "../DeleteModal/DeleteModal";

const ModalCustom = ({title,children})=> {
        const modalQueue = useSelector(isModalOpen)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(modalClose());
    };



    return (
        <Dialog onClose={handleClose} open={!!modalQueue[0]} sx={{minWidth:600, minHeight:'95vh' }} >
            <DialogTitle fontWeight='bold' textAlign='center'>{title}</DialogTitle>
            <Box sx={{minWidth:600, padding:2}} >
                {modalQueue[0] === 'createBook' &&  <CreateBook/>}
                {modalQueue[0] === 'createPart' &&  <CreatePart/>}
                {modalQueue[0] === 'createPage' &&  <CreatePage/>}
                {modalQueue[0] === 'UploadImage' &&  <UploadImg placeholder={'введите описание'} textButton={'добавить обложку'}  type='book'/>}
                {modalQueue[0] === 'UploadImagePage' &&  <UploadImg placeholder={'введите описание'} textButton={'добавить решение'} type='page'/>}
                {modalQueue[0] === 'DeleteBook' &&  <DeleteModal howDelete={'книгу'} type='book'/>}
                {modalQueue[0] === 'DeletePart' &&  <DeleteModal howDelete={'раздел'} type='part'/>}
                {modalQueue[0] === 'DeletePage' &&  <DeleteModal howDelete={'страницу'} type='page'/>}
            </Box>
            </Dialog>
    );
}

export default ModalCustom
