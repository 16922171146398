import Box from "@mui/material/Box";
import {EditableText} from "../../components/Editable";
import Button from "@mui/material/Button";
import {changeTextNewPart, newPart} from "../../redux/creators/creatorsSlice";
import {useDispatch, useSelector} from "react-redux";

import {useParams} from "react-router-dom";
import {useCreatPartMutation} from "../../redux/creators/creatorsApi";
import {modalClose} from "../../redux/app/appSlice";
import {useGetBookPartsQuery, useGetBookQuery} from "../../redux/book/bookApi";


const CreatePart = () => {
    const part = useSelector(newPart)
    const dispatch = useDispatch()
    const [creatPart] = useCreatPartMutation();
    const { bookId } = useParams();
    const { data: book } = useGetBookQuery(bookId);
    const { data: parts, refetch} = useGetBookPartsQuery(bookId);

    const onChangeText = (item)=>{
        dispatch(changeTextNewPart({...item, ordr:parts.length + 1}))
    }

    const onClickCreatPart = () =>{
        creatPart({...part,id:bookId})
        refetch()
        dispatch(modalClose())
    }

    const validate = part.name;
    return (
        <Box>
            <Box display="flex" flexDirection="row">
                <Box >
                    <Box>
                        <EditableText editKey={'name'} data={part} onUpdate={onChangeText} initialText={part.name} variant={'h6'} title={'Название части:'} required />
                    </Box>
                </Box>
            </Box>
            <Box sx={{textAlign:'center',marginBottom:3, marginTop:3}}>
            <Button variant='outlined' disabled={!validate} onClick={onClickCreatPart}>Добавить часть</Button>
            </Box>
        </Box>
    );
};

export default CreatePart;
