import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Input } from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";
import { setNotification } from "../../../redux/app/appSlice";

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    minHeight: "5vh",
    marginTop: -5,
    display: 'flex',
    borderRadius: "10px",
    paddingRight: "25px",
    '& button': {
        display: 'none',
        right: '1',
        padding: 0,
    },
    '&:hover': {
        backgroundColor: theme.palette.grey.A400,
        '& button': {
            display: 'block',
            position: 'absolute',
            right: '0',
        },
    },
}));

const EditableText = ({
                          initialText,
                          onClick,
                          sx,
                          variant,
                          color,
                          type = 'text',
                          editKey,
                          data,
                          onUpdate,
                          title,
                          required
                      }) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(initialText);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    useEffect(() => {
        setText(initialText)
    }, [data]);

    const handleSaveClick = () => {
        try {
            if (onUpdate) {
                dispatch(onUpdate({ ...data, [editKey]: text }));
            }
            dispatch(setNotification("Изменено"))
        } catch (e) {
            console.log(e);
        }
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSaveClick();
        } else if (e.keyCode === 27) {
            // Если нажата клавиша "Esc", выход из режима редактирования без сохранения
            setIsEditing(false);
            setText(initialText); // Восстановление исходного текста
        }
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    if (isEditing) {
        return (
            <Box display='flex' flexDirection='row'>
                <Typography sx={{ ...sx, fontWeight: 'bold' }} variant={variant}>
                    {title}
                </Typography>
                <Box display={'flex'} flexDirection={'row'} width={'44vw'}>
                    <Input
                        value={text}
                        onChange={handleTextChange}
                        onBlur={handleSaveClick}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        size={'small'}
                        type={type}
                        color={required && !text ? 'error' : ''}
                    />
                    <IconButton size={'small'} variant="outlined" onClick={handleSaveClick}>
                        {required && !text ? <CloseIcon /> : <SaveIcon />}
                    </IconButton>
                </Box>
            </Box>
        );
    }

    return (
        <StyledBox
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={3}
            className={isEditing ? 'editable-text hover' : 'editable-text'} // Добавление класса в зависимости от режима редактирования
        >
            <Typography sx={{ ...sx, fontWeight: 'bold' }} variant={variant}>
                {title}
            </Typography>
            <Typography sx={sx} color={required && !text ? 'error' : color}>
                {text ? text : '---'}
            </Typography>
            {!isEditing && (
                <IconButton
                    className="edit-button"
                    size={'small'}
                    variant="outlined"
                    onClick={handleEditClick}
                >
                    <EditIcon />
                </IconButton>
            )}
        </StyledBox>
    );
};

export default EditableText;
