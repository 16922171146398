import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "../baseQuery";

export const bookApi = createApi({
  reducerPath: 'api/book',
  baseQuery,
  endpoints: builder => ({
    getBook: builder.query({
      query: (data) => ({
        url: `/books/${data}`,
        method: 'GET',
      }),
      providesTags: ['book']
    }),
    getBookParts: builder.query({
      query: (data) => ({
        url: `/books/${data}/parts/hierarchy`,
        method: 'GET',
      }),
      providesTags: ['book']
    }),
    updateBook: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data.id}`,
          method: 'PUT',
          body: data
        }},
      invalidatesTags: ['book'],
    }),
    addBookImg: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data.id}/images`,
          method: 'POST',
          body: {title: data.title}
        }},
      invalidatesTags: ['book'],
    }),
    uploadBookImg: builder.mutation({
      query: (data) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', data.file);

        return {
          url: `books/${data.id}/images/${data.imgId}/content`,
          method: 'PUT',
          body:  bodyFormData ,
          formData:true
        }},
      invalidatesTags: ['book'],
    }),
    updateTitleImg: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data.id}/images/${data.imgId}`,
          method: 'PUT',
          body: {title:data.title} ,
          formData:true
        }},
      invalidatesTags: ['book'],
    }),
    deleteBook: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data}`,
          method: 'DELETE',
        }},
      invalidatesTags: ['book'],
    }),
    deletePart: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data.bookId}/parts/${data.partId}`,
          method: 'DELETE',
        }},
      invalidatesTags: ['book'],
    }),
    deletePage: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data.bookId}/parts/${data.partId}/pages/${data.pageId}`,
          method: 'DELETE',
        }},
      invalidatesTags: ['book'],
    }),
    deleteBookImg: builder.mutation({
      query: (data) => {
        return {
          url: `books/${data.id}/images/${data.imgId}`,
          method: 'DELETE',
        }},
      invalidatesTags: ['book'],
    }),
    getPage: builder.query({
      query: (data) => ({
        url: `/books/${data.bookId}/parts/${data.partId}/pages/${data.pageId}`,
        method: 'GET',
      }),
      providesTags: ['book']
    }),
    updatePage: builder.mutation({
      query: (data) => {
        console.log(data,'data')
        return {
          url: `/books/${data.bookId}/parts/${data.partId}/pages/${data.pageId}`,
          method: 'PUT',
          body: { ...data.page, bookimageTitle:undefined,id:undefined,image:{id:data.page.image.id}}
        }},
      invalidatesTags: ['book'],
    }),
    updateDescriptionBookList: builder.mutation({
      query: (data) => {
        return {
          url: `/classes/${data.classId}/subjects/${data.subjectId}`,
          method: 'PUT',
          body: { ordr: 1, descriptionBottom: data.descriptionBottom }
        }},
      invalidatesTags: ['book'],
    }),
  })
});

export const { useGetBookQuery, useGetBookPartsQuery, useGetPageQuery, useUpdatePageMutation , useUpdateBookMutation, useAddBookImgMutation, useUploadBookImgMutation, useUpdateTitleImgMutation, useDeleteBookMutation, useDeletePartMutation, useDeletePageMutation, useDeleteBookImgMutation, useUpdateDescriptionBookListMutation } = bookApi;
