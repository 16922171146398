import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from 'react-redux';
import {store} from "./redux/store";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {HashRouter} from "react-router-dom";

const defaultTheme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReduxProvider store={store}>
        <ThemeProvider theme={defaultTheme}>
         <React.StrictMode>
             <HashRouter>
                <App />
             </HashRouter>
        </React.StrictMode>
        </ThemeProvider>
    </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
