import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from "@mui/material/Button";
import { setNotification } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";
import {Typography} from "@mui/material";

const EditableHtml = ({ html, title, editKey, onUpdate, data, variant = 'h6' }) => {
    const [editorData, setEditorData] = useState(html);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()

    const handleSave = () => {
        if (onUpdate) {
            onUpdate({ ...data, [editKey]: editorData });
            dispatch(setNotification("Изменено"))
            setOpen(false)
        }
    };

    const openEditor = () => {
        setOpen(true)
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 27) { // Check if Esc key is pressed
            setOpen(false)
        }
    };

    const editorConfig = {
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo'
            ]
        }
    };

    return (
        <Box marginBottom={1} onKeyDown={handleKeyDown}>
            <Typography variant={variant} sx={{marginTop:-2, fontWeight:'bold'}}>{title}</Typography>
            {open ?
                <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    config={editorConfig}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setEditorData(data);
                    }}
                />
                :
                <div dangerouslySetInnerHTML={{ __html: html }} />
            }
            {open ?
                <Button variant={'outlined'} disabled={html === editorData} onClick={handleSave} sx={{ marginTop: 1 }}>Сохранить</Button>
                :
                <Button variant={'outlined'} onClick={openEditor} sx={{ marginTop: 1 }}>Изменить</Button>
            }
        </Box>
    );
};

export default EditableHtml;
