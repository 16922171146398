import Box from "@mui/material/Box";
import {useEffect} from "react";
import {EditableText} from "../../components/Editable";
import Button from "@mui/material/Button";
import {changeTextNewBook, newBook} from "../../redux/creators/creatorsSlice";
import {useDispatch, useSelector} from "react-redux";
import SimpleSelect from "../SearchSelect";
import {
    useGetPublishersQuery,
    useGetSeriesQuery,
    useGetSubjectsQuery,
    useGetTypesQuery
} from "../../redux/selects/selectsApi";
import {useParams} from "react-router-dom";
import {useCreatBookMutation} from "../../redux/creators/creatorsApi";
import {getTranslit} from "../../helpers/getTranslit";
import {modalClose} from "../../redux/app/appSlice";
import {useGetListBookQuery} from "../../redux/public/publicApi";


const CreateBook = () => {
    const book = useSelector(newBook)
    const dispatch = useDispatch()
    const { classId, subjectId } = useParams();
    const { data:types } = useGetTypesQuery();
    const { data:series } = useGetSeriesQuery();
    const { data:publisher } = useGetPublishersQuery();
    const { data:subjects } = useGetSubjectsQuery();
    const [creatBook] = useCreatBookMutation();
    const { refetch } = useGetListBookQuery({ classId, subjectId });
    useEffect(
        ()=>{
          dispatch(changeTextNewBook({...book,
              class:{id:Number(classId.replace('class-',''))},
              subject:{id:subjects?.find((subject)=>subject.uid === subjectId).id}}))
        },[ classId, subjectId, subjects]
    )

    const onChangeText = (item)=>{
        dispatch(changeTextNewBook(item))
    }
    const onChangeName =(item)=>{
        dispatch(changeTextNewBook({...item,uid:getTranslit(item.name)}))
    };
    const onChangeSelected = (item,editKey)=>{
        if(editKey === 'type'
        ) {
            dispatch(changeTextNewBook({...book,[editKey]:item}))
        } else {
            dispatch(changeTextNewBook({...book,[editKey]:[item]}))
        }

    }

    const onClickCreatBook = () =>{
        creatBook(book)
        refetch()
        dispatch(modalClose())
    }

    const validate = book.name && book.uid && book.author && book.publicationYear;

    return (
        <Box>
            <Box display="flex" flexDirection="row">
                <Box >
                    <Box>
                        <EditableText editKey={'name'} data={book} onUpdate={onChangeName} initialText={book.name} variant={'h6'} title={'Название книги:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'uid'} data={book} onUpdate={onChangeText} initialText={book.uid} variant={'h6'} title={'UID:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'author'} data={book} onUpdate={onChangeText} initialText={book.author} variant={'h6'} title={"Автор:"} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'publicationYear'} data={book} onUpdate={onChangeText} initialText={book.publicationYear} variant={'h6'} title={"Год публикации:"} required/>
                    </Box>
                    <Box>
                        <EditableText editKey={'title'} data={book} onUpdate={onChangeText} initialText={book.title} variant={'h6'} title={'Заголовок:'} />
                    </Box>
                    <Box>
                        <EditableText editKey={'description'} data={book} onUpdate={onChangeText} initialText={book.description} variant={'h6'} title={"Описание:"} />
                    </Box>
                    <Box>
                        <EditableText editKey={'keywords'} data={book} onUpdate={onChangeText} initialText={book.keywords} variant={'h6'} title={"Ключи:"} />
                    </Box>
                    <Box sx={{width:"250px"}}>
                        <SimpleSelect editKey={'type'} option={types} placeholder='Типы' onChange={onChangeSelected}/>
                    </Box>
                    <Box sx={{width:"250px"}}>
                        <SimpleSelect editKey={'series'} option={series} placeholder='Серия' onChange={onChangeSelected}/>
                    </Box>
                    <Box sx={{width:"250px"}}>
                        <SimpleSelect editKey={'publishers'} option={publisher} placeholder='Издатель' onChange={onChangeSelected}/>
                    </Box>
                </Box>
            </Box>
            <Box sx={{textAlign:'center',marginBottom:3}}>
            <Button variant='outlined' onClick={onClickCreatBook} disabled={!validate}>Создать Книгу</Button>
            </Box>
        </Box>
    );
};

export default CreateBook;
