import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 book:{
     ordr: 0,
     uid: "",
     name: "",
     title: "",
     description: "",
     keywords: "",
     author: "",
     publicationYear: "",
     descriptionTop: "",
     descriptionBottom: "",
     class: {},
     subject: {},
     type: {},
     series: [],
     publishers: [],
     covers: []
    },
    part:{
        id: null,
        ordr: null,
        name: "",
        parentId: null
    },
    page:{
        parentId: null,
        name: "",
        uid: "",
        num:"",
        ordr: null,
        content:"",
        title: "",
        description: "",
        keywords: "",
    }
};

export const creatorsSlice = createSlice({
    initialState,
    name: 'creators',
    reducers: {
        changeTextNewBook: (state, action) => {
            state.book = action.payload;
        },
        changeTextNewPart: (state, action) => {
            state.part = action.payload;
        },
        changeParentId: (state, action) => {
            state.part.parentId = action.payload;
        },
        changeTextNewPage: (state, action) => {
            state.page = action.payload;
        },
        changeParentIdPage: (state, action) => {
            state.page.parentId = action.payload;
        },
    }
});

export default creatorsSlice.reducer;

export const newBook = (state) => state.creators.book;
export const newPart = (state) => state.creators.part;
export const newPage = (state) => state.creators.page;

export const { changeTextNewBook, changeTextNewPart,changeParentId,changeTextNewPage,changeParentIdPage } = creatorsSlice.actions;


