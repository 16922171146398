import { createApi } from '@reduxjs/toolkit/query/react';
import { setToken } from './appSlice';
import baseQuery from '../baseQuery';

export const appApi = createApi({
  reducerPath: 'api/app',
  baseQuery: baseQuery,
  endpoints: builder => ({
    loginUser: builder.mutation({
      query: data => ({
        url: '/login',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
          localStorage.setItem('token', data.token);
        } catch (error) {}
      }
    }),
  })
});


export const {
  useLoginUserMutation,
} = appApi;
