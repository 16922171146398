import React from 'react';
import Select from 'react-select';
import {setNotification} from "../../redux/app/appSlice";
import {useDispatch} from "react-redux";

const SearchSelect = ({ option,placeholder, onChange,editKey,sx, value }) => {

    const handleChange = (selectedOption) => {
        onChange(selectedOption,editKey)
    };
    return (
        <div>
            <Select
                isSearchable={true}
                name="color"
                options={option}
                getOptionLabel ={(option)=>option.name}
                getOptionValue ={(option)=>option.id}
                placeholder={placeholder}
                onChange={handleChange}
                value={value?value:undefined}
            />

            <div
                style={{
                    color: 'hsl(0, 0%, 40%)',
                    display: 'inline-block',
                    fontSize: 12,
                    fontStyle: 'italic',
                    marginTop: '1em',
                }}
            >
                {/* Дополнительный контент или сообщение */}
            </div>
        </div>
    );
};

export default SearchSelect;
