import React from 'react';
import {Card,  CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";


const ChoosingCard = ({ chooseItem }) => {
    if (!chooseItem.name) {
        return null;
    }
    return (
        <Card             sx={{
            minWidth: 275,
            marginBottom: 1,
            paddingTop:"10px",
            point:'event',
            "&:hover": {
                backgroundColor: "#e0e0e0",
            },
        }}>
            <CardContent component={Link} to={chooseItem.uid}>
                <Typography fontWeight={'bold'}>{chooseItem.name}</Typography>
            </CardContent>
        </Card>
    );
};

export default ChoosingCard;
