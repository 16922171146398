import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';

export const creatorsApi = createApi({
  reducerPath: 'api/creators/',
  baseQuery: baseQuery,
  endpoints: builder => ({
    creatBook: builder.mutation({
      query: data => ({
        url: '/books',
        method: 'POST',
        body: data
      }),
    }),
    creatPart: builder.mutation({
      query: data => ({
        url: `/books/${data.id}/parts`,
        method: 'POST',
        body: {
          ordr:data.ordr,
          name:data.name,
          parentId:data.parentId,
        }
      }),
    }),
    creatPage: builder.mutation({
      query: data => ({
        url: `/books/${data.bookId}/parts/${data.parentId}/pages`,
        method: 'POST',
        body: {
          ordr: data.ordr,
          uid: data.uid,
          name: data.name,
          title: data.title,
          description: data.description,
          keywords: data.keywords,
          content: data.content,
          num:data.num,
        }
      }),
    }),
  })
});


export const {
  useCreatBookMutation,
  useCreatPartMutation,
  useCreatPageMutation,
} = creatorsApi;
