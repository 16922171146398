const menu = [
    {
        id: 'Main',
        href: '/',
        name:"Главная",
        icon: null,
    },
    {
        id: 'Edit',
        href: '/Edit',
        name:"Редактирование",
        icon: null,
    },

];

export default menu;
