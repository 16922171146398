import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router-dom";
import {useDeleteBookMutation, useDeletePageMutation, useDeletePartMutation} from "../../redux/book/bookApi";
import {modalClose, partNow} from "../../redux/app/appSlice";
import {useDispatch, useSelector} from "react-redux";
import book from "../../pages/book/Book";
import {Typography} from "@mui/material";
import {useGetListBookQuery} from "../../redux/public/publicApi";


const CreatePart = ({howDelete, type}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const partIdNow = useSelector(partNow)
    const { classId ,subjectId, bookId, partId, pageId } = useParams();
    const [deleteBook] = useDeleteBookMutation()
    const [deletePart] = useDeletePartMutation()
    const [deletePage] = useDeletePageMutation()
    const { refetch:refetchListBook } = useGetListBookQuery({ classId, subjectId});


    const onDelete = () =>{
        if(type==='book'){
            deleteBook(bookId)
            refetchListBook()
            navigate(`/${classId}/${subjectId}`)
            dispatch(modalClose())
        } else if(type==='part'){
            deletePart({bookId,partId:partIdNow})
            dispatch(modalClose())
        } else if(type==='page'){
            deletePage({bookId,partId,pageId})
            navigate(`/${classId}/${subjectId}/${bookId}`)
            dispatch(modalClose())
        }
    }

    const closeModal =()=>{
        dispatch(modalClose())
    }

    return (
        <Box>
          <Typography variant={'h6'} textAlign={'center'} marginBottom={1}>  Вы хотите удалить {howDelete} ?</Typography>
            <Box display={"flex"} justifyContent={'space-around'}>
                <Button size={'large'} onClick={onDelete} variant={'outlined'} color={'error'}> ДА </Button>
                <Button size={'large'} onClick={closeModal} variant={'outlined'} color={'success'}> НЕТ </Button>
                </Box>
        </Box>
    );
};

export default CreatePart;
