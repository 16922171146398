import useBreadcrumbs from "use-react-router-breadcrumbs";
import {Breadcrumbs} from "@mui/material";
import {Link} from "react-router-dom";

const BreadcrumbsMain = () => {
    const breadcrumbs = useBreadcrumbs()
    const linksBreadcrumbs = breadcrumbs.map((item,index)=>{
        return <Link key={index} underline="hover" color="inherit" to={item.breadcrumb.key}>
            {item.breadcrumb.props.children}
        </Link>
    })

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom:2}}>
            {linksBreadcrumbs}
        </Breadcrumbs>
    );
}

export default BreadcrumbsMain;

