import { lazy } from 'react';
import Loadable from "../../components/Loadable";
import MinimalLayout from "../../layout/minimalLayout/MinimalLayout";

const AuthLogin = Loadable(lazy(() => import('../../pages/login/Login')));

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: '*',
      element: <AuthLogin />
    },
  ]
};

export default LoginRoutes;
