import { configureStore } from '@reduxjs/toolkit';
import {publicApi} from "./public/publicApi";
import {appSlice} from "./app/appSlice";
import {selectsApi} from "./selects/selectsApi";
import {appApi} from "./app/appApi";
import {creatorsSlice} from "./creators/creatorsSlice";
import {creatorsApi} from "./creators/creatorsApi";
import {bookApi} from "./book/bookApi";

export const store = configureStore({
  reducer: {
    [publicApi.reducerPath]: publicApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
    app: appSlice.reducer,
    [creatorsApi.reducerPath]: creatorsApi.reducer,
    creators: creatorsSlice.reducer,
    [selectsApi.reducerPath]: selectsApi.reducer,
    [bookApi.reducerPath]: bookApi.reducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([publicApi.middleware, selectsApi.middleware, appApi.middleware, creatorsApi.middleware,bookApi.middleware])
});
