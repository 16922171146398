import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

const Parts = () => {
    const { classId ,subjectId, bookId, partId, pageId } = useParams();
    const navigate = useNavigate();
    useEffect(() =>{
        navigate(`/${classId}/${subjectId}/${bookId}`)
    },[])
    return null
};

export default Parts;
