import { createSlice } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

const initialState = {
  title:'',
  auth:{
    token: localStorage.getItem('token'),
    xToken: localStorage.getItem('xToken'),
  },
  notifications:[],
  modal:[],
  loading:false,
  bookNow:null,
  partNow:null,
};

export const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    changeTitle: (state, action) => {
      state.title = action.payload.title;
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('xToken');
      state.auth.token = null;
      state.auth.xToken = null;
    },
    setToken: (state, action) => {
      state.auth.token = action.payload;
    },
    setSecretKey: (state, action) => {
      localStorage.setItem('xToken', action.payload);
      state.auth.xToken = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(item => item.id !== action.payload);
    },
    modalClose: (state) => {
      state.modal=[];
    },
    modalOpen: (state,action) => {
      state.modal.push(action.payload);
    },
    addBookNow: (state, action) => {
      state.bookNow = action.payload;
    },
    addPartNow: (state, action) => {
      state.partNow = action.payload;
    }
  }
});

export default appSlice.reducer;

export const { changeTitle, logoutUser, setToken, setSecretKey, addNotification, removeNotification, modalClose, modalOpen, addBookNow, addPartNow } = appSlice.actions;
export const getTitle = (state) => state.app.title;
export const selectNotifications = (state) => state.app.notifications;
export const selectAuth = (state) => state.app.auth;
export const isModalOpen = (state) => state.app.modal;
export const bookNow = (state) => state.app.bookNow;
export const partNow = (state) => state.app.partNow;


export const setNotification = (content, type = 'success', timeout = 5000) => (dispatch) => {
  const id = v4();
  const data = {
    id,
    type,
    content,
    timeout
  };
  dispatch(addNotification(data));
  setTimeout(() => dispatch(removeNotification(id)), timeout);
};

