import Box from "@mui/material/Box";
import {EditableText} from "../../components/Editable";
import Button from "@mui/material/Button";
import {changeTextNewPage, newPage} from "../../redux/creators/creatorsSlice";
import {useDispatch, useSelector} from "react-redux";

import {useParams} from "react-router-dom";
import {useCreatPageMutation} from "../../redux/creators/creatorsApi";
import {modalClose} from "../../redux/app/appSlice";
import {useGetBookPartsQuery, useGetBookQuery} from "../../redux/book/bookApi";
import {getTranslit} from "../../helpers/getTranslit";


const CreatePage = () => {
    const page = useSelector(newPage)
    const dispatch = useDispatch()
    const [creatPage] = useCreatPageMutation();
    const { bookId } = useParams();
    const { data: book } = useGetBookQuery(bookId);
    const { data: parts, refetch} = useGetBookPartsQuery(bookId);
    const onChangeText = (item)=>{
        dispatch(changeTextNewPage({...item }))
    }
    const onChangeName =(item)=>{
        dispatch(changeTextNewPage({...item,uid:getTranslit(item.name)}))
    };
    const onClickCreatPart = () => {
        creatPage({...page, bookId: book.id,parentId:page.parentId})
        refetch()
        dispatch(modalClose())
    }

    const validate = page.name;

    return (
        <Box>
            <Box display="flex" flexDirection="row">
                <Box >
                    <Box>
                        <EditableText editKey={'name'} data={page} onUpdate={onChangeName} initialText={page.name} variant={'h6'} title={'Название страницы:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'uid'} data={page} onUpdate={onChangeText} initialText={page.uid} variant={'h6'} title={'UID:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'num'} data={page} onUpdate={onChangeText} initialText={page.num} variant={'h6'} title={'Номер страницы:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'ordr'} type={'number'} data={page} onUpdate={onChangeText} initialText={page.ordr} variant={'h6'} title={'Порядок:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'description'} data={page} onUpdate={onChangeText} initialText={page.description} variant={'h6'} title={'Описание:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'title'} data={page} onUpdate={onChangeText} initialText={page.title} variant={'h6'} title={'Заголовок:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'content'} data={page} onUpdate={onChangeText} initialText={page.content} variant={'h6'} title={'Контент:'} required />
                    </Box>
                    <Box>
                        <EditableText editKey={'keywords'} data={page} onUpdate={onChangeText} initialText={page.keywords} variant={'h6'} title={'Ключи:'} required />
                    </Box>
                </Box>
            </Box>
            <Box sx={{textAlign:'center',marginBottom:3, marginTop:3}}>
            <Button variant='outlined' onClick={onClickCreatPart} disabled={!validate}>Добавить страницу</Button>
            </Box>
        </Box>
    );
};

export default CreatePage;
