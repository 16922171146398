import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "../baseQuery";

export const publicApi = createApi({
  reducerPath: 'api/public',
  baseQuery,
  endpoints: builder => ({
    getClasses: builder.query({
      query: () => ({
        url: 'public/classes',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    getHierarchy: builder.query({
      query: () => ({
        url: '/public/classes/hierarchy',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    getSubject: builder.query({
      query: () => ({
        url: '/public/subjects/',
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    getListBook: builder.query({
      query: (data) => ({
        url: `/public/books/${data.classId}/${data.subjectId}`,
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    getBook: builder.query({
      query: (data) => ({
        url: `/public/books/${data.classId}/${data.subjectId}/${data.bookId}`,
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    getPage: builder.query({
      query: (data) => ({
        url: `/public/books/${data.classId}/${data.subjectId}/${data.bookId}/${data.pageId}`,
        method: 'GET',
      }),
      providesTags: ['public']
    }),
    getBooksBySubject: builder.query({
      query: (data) => ({
        url: `/public/booksBySubject/${data.subjectId}`,
        method: 'GET',
      }),
      providesTags: ['public']
    }),
  })
});

export const { useGetClassesQuery, useGetHierarchyQuery, useGetSubjectQuery, useGetListBookQuery, useGetBookQuery, useGetPageQuery, useGetBooksBySubjectQuery } = publicApi;
