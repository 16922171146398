import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {changeTitle} from "../redux/app/appSlice";

const useUpdateTitle = (newTitle) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeTitle({ title: newTitle }));
    }, [newTitle]);
};

export default useUpdateTitle;
